import React from "react";
import { ThemeProvider } from "styled-components";

import GlobalStyles from "./src/style/global";
import theme from "./src/style/theme";

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </>
  );
};
