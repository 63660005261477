import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        box-sizing: border-box;
        font-family: 'open sans', Arial, Helvetica, sans-serif;
        scroll-behavior: smooth;
    }
`;

export default GlobalStyles;
