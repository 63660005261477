const theme = {
  background: {
    primary: "#050625FF",
    highlight: "#8489FBFF",
    light: "#FFFFFF",
    altLight: "#DEDFFDFF",
    altLight2: "#F1F1F1",
  },
  text: {
    primary: "#050625FF",
    alt: "#76777FFF",
    highlight: "#8489FBFF",
    light: "#FFFFFF",
    altLight: "#DEDFFDFF",
    altLight2: "#F1F1F1",
  },
  device: {
    xs: "(max-width: 400px)",
    sm: "(max-width: 600px)",
    md: "(max-width: 950px)",
    lg: "(max-width: 1280px)",
  },
};

export default theme;
